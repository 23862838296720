/** Remains: Tree video **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data, preloadData;

class RemainsPage1 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[1];
    preloadData = globalState.assets[2];

    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

    //add base track
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1); //tree loop
    globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2, 60); //base track

    
    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.caption,
      globalState.cdnUrl + preloadData.background,
      globalState.cdnUrl + preloadData.building1,
      globalState.cdnUrl + preloadData.building1Color,
      globalState.cdnUrl + preloadData.building1,
      globalState.cdnUrl + preloadData.building1Color,
      globalState.cdnUrl + preloadData.building2,
      globalState.cdnUrl + preloadData.building2Color,
      globalState.cdnUrl + preloadData.building3,
      globalState.cdnUrl + preloadData.building3Color,
      globalState.cdnUrl + preloadData.building4,
      globalState.cdnUrl + preloadData.building4Color,
      globalState.cdnUrl + preloadData.building5,
      globalState.cdnUrl + preloadData.building5Color,
      globalState.cdnUrl + preloadData.building6,
      globalState.cdnUrl + preloadData.building6Color,
      globalState.cdnUrl + preloadData.building7,
      globalState.cdnUrl + preloadData.building7Color,
    ], this.preloadCallback);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

    //remove page track
    //globalState.baseAudioRef.updateTrack(1, false);

  }

  bgVideoEnded = () => {
    console.log("Bg Video ended");
      //this.bgVideoRef.seekVideo(30);
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop, 0);

    } else {
      //hide text
      hideText(this.refs.text1Ref, "right");
    }
  }

  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }

  

  render() {

    return (

      <>

        <link rel="prefetch" href="/remains/2" />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/remains/2" prevLocation="/remains"
          isTransitioning={this.isTransitioning} />
        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Remains" />

          <div className="text-wrapper">
            <div className="text text--right-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "2vw", marginTop: "5vh" }} onClick={this.toggleTextDisplay} alt="PINE POINTERS STILL VISIT THEIR HOMETOWN. Some travel thousands of miles to get there. They bring family, friends. They reminisce, or introduce the place to people who’ve never been or were too young to remember or weren’t born yet. Just over there – some proof that it all really happened." />
            </div>
          </div>

          
          {data.bgVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
              poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={true} muted={true} ended={this.bgVideoEnded} ref={div => this.bgVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
          }


        </div>
        )}
      </>
    );
  }


};

export default RemainsPage1
